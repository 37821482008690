import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import ScanWristbandIcon from "../svg/ScanWristbandIcon";
import { AnimatePresence, motion } from "framer-motion";
import AppleStore from "../svg/AppleStore";
import GooglePlayStore from "../svg/GooglePlayStore";
import AppleStoreQR from "../svg/AppleStoreQR";
import GooglePlayStoreQR from "../svg/GooglePlayStoreQR";
import { selectConfig } from "../../features/config/configSlice";
import AppStoreQRRKV from "../svg/AppStoreQRRKV";
import AppStoreQR from "../svg/AppStoreQR";

export function MapScanWristband() {
	const mapScreen = useSelector(selectMapScreen);
	const config = useSelector(selectConfig);

	return (
		<>
			{mapScreen.State === "CityHost" && (
				<div className="absolute top-0 left-1/2 -translate-x-1/2 z-10 h-screen min-w-128">
					<AnimatePresence>
						{mapScreen.State === "CityHost" && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="absolute inset-0"
							>
								{/* <div className="relative h-screen flex flex-col space-y-12 justify-center items-center">
									<motion.div
										animate={{
											y: [20, 0, 20],
										}}
										transition={{
											duration: 1,
											ease: "easeInOut",
											times: [0, 0.2, 0.5],
											repeat: Infinity,
											repeatDelay: 5,
										}}
										className="flex flex-col justify-center items-center"
									>
										<ScanWristbandIcon className="w-24 h-24" />
										<span className="text-h2 text-center">
											Scan your wristband
										</span>
									</motion.div>
								</div> */}

								<div className="absolute bottom-2 w-full flex flex-col justify-center items-center space-y-2 ">
									<span className="text-h4 text-gray-800">
										Download the app
									</span>
									<div className="flex justify-center items-center space-x-4">
										{config.Property?.Abbreviation === "RKV" ? (
											<div className="flex flex-col w-36 p-2 justify-center items-center space-y-4">
												<AppStoreQRRKV className="w-24 h-24 rounded-md"/>
											</div>
										) : (
											<div className="flex flex-col w-36 p-2 justify-center items-center space-y-4">
												<AppStoreQR className="w-24 h-24 rounded-md" />
											</div>
										)}
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</>
	);
}
